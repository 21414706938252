
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class pais extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  table_all_data = []
  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }
  table_total = 0
  table_search_data = {
    user_name: '',
    phone: '',
    age: '',
    sex: ''
  }

  rule_table_search_data = {}

  users_detail_dialog = {
    open: false,
    title: '详情'
  }

  users_detail_data = {
    name: '',
    phone: '',
    dept_name: '',
    roles: '',
    status: 0,
    first_name: '',
    last_name: '',
    post_status: 1,
    self_evaluation: '',
    sex: 1,
    birthday: '',
    culture: 0,
    post: '',
    post_rank: '',
    age_limit: 0,
    id_number: '',
    nature: 0,
    duty: '',
    social_position: '',
    wechat: '',
    email: '',
    industry_category: '',
    related_industries: '',
    area: [],
    is_verify: 0,
    is_patent: 0,
    patent_code: '',
    patent_file: [] as any[],
    is_market: 0,
    market_code: '',
    market_file: [] as any[],
    avatar: '',
    banks: [] as any[]
  }

  table_edit_dialog = {
    open: false,
    title: '审核'
  }

  table_edit_data = {
    id: 0,
    sub_opinion: '',
    status_: 1
  }

  rule_table_edit_data = {}

  sex_735 = new Map<any, string>([
    [1, '男'],
    [2, '女']
  ])
  status_736 = new Map<any, string>([
    [0, '待审核'],
    [1, '通过审核'],
    [2, '不通过审核'],
    [3, '通过审核'],
    [4, '通过审核']
  ])
  status__737 = new Map<any, string>([
    [1, '通过审核'],
    [2, '不通过审核']
  ])

  table_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      user_name: this.table_search_data.user_name,
      phone: this.table_search_data.phone,
      age: this.table_search_data.age,
      sex: this.table_search_data.sex
    }
    Api.http_paistable0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table_all_data = res.data.data
        this.table_total = res.data.total
      }
    })
  }
  addressData: any[] = []
  get_addressData(id = -1) {
    Api.http_userusersaddressData0({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.addressData = res.data.data
        }
      }
    )
  }
  users_detail_init(row) {
    this.get_addressData(row.user_id)

    Api.http_userusersget4({ id: row.user_id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.users_detail_data.name = res.data.name
        this.users_detail_data.banks = res.data.banks ? res.data.banks : []
        this.users_detail_data.phone = res.data.phone
        this.users_detail_data.avatar = res.data.avatar
        this.users_detail_data.id_number = res.data.id_number
        this.users_detail_data.dept_name = res.data.dept_name
        this.users_detail_data.roles = res.data.roles
        this.users_detail_data.status = res.data.status
        this.users_detail_data.first_name = res.data.first_name
        this.users_detail_data.last_name = res.data.last_name
        this.users_detail_data.post_status = res.data.post_status
        this.users_detail_data.self_evaluation = res.data.self_evaluation
        this.users_detail_data.sex = res.data.sex
        this.users_detail_data.birthday = res.data.birthday
        this.users_detail_data.culture = res.data.culture
        this.users_detail_data.post = res.data.post
        this.users_detail_data.post_rank = res.data.post_rank
        this.users_detail_data.age_limit = res.data.age_limit
        this.users_detail_data.nature = res.data.nature
        this.users_detail_data.duty = res.data.duty
        this.users_detail_data.social_position = res.data.social_position
        this.users_detail_data.wechat = res.data.wechat
        this.users_detail_data.email = res.data.email
        this.users_detail_data.industry_category = res.data.industry_category
        this.users_detail_data.related_industries = res.data.related_industries
        this.users_detail_data.area = res.data.area
        this.users_detail_data.is_verify = res.data.is_verify
        this.users_detail_data.is_patent = res.data.is_patent
        this.users_detail_data.patent_code = res.data.patent_code
        this.users_detail_data.patent_file = res.data.patent_file
        this.users_detail_data.is_market = res.data.is_market
        this.users_detail_data.market_code = res.data.market_code
        this.users_detail_data.market_file = res.data.market_file
        this.users_detail_dialog.open = true
      }
    })
  }
  users_detail_cancle() {
    this.users_detail_dialog.open = false
  }
  users_detail_ok() {
    this.users_detail_dialog.open = false
  }
  post_status_25 = new Map<any, string>([
    [1, '在职'],
    [2, '离职']
  ])
  culture_29 = new Map<any, string>([
    [1, '博士'],
    [4, '硕士'],
    [3, '本科'],
    [4, '大专'],
    [5, '大专以下']
  ])
  nature_33 = new Map<any, string>([
    [1, '高校'],
    [2, '科研机构'],
    [3, '科技服务机构'],
    [4, '医院'],
    [5, '社会团体'],
    [6, '其他事业单位'],
    [7, '军队武警'],
    [8, '国企'],
    [9, '民企'],
    [10, '外企'],
    [11, '混合所有制企业'],
    [12, '境外机构'],
    [13, '其他组织']
  ])
  is_patent_44 = new Map<any, string>([
    [1, '是'],
    [0, '否']
  ])
  is_market_47 = new Map<any, string>([
    [1, '是'],
    [0, '否']
  ])
  table_edit_init(row) {
    Api.http_paistableget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table_edit_data.id = row.id
        this.table_edit_data.sub_opinion = res.data.sub_opinion
        this.table_edit_data.status_ = res.data.status_
        this.table_edit_dialog.open = true
      }
    })
  }
  table_edit_cancle() {
    this.table_edit_dialog.open = false
  }
  table_edit_ok() {
    this.$refs.ref_table_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.table_edit_data.id,
          sub_opinion: this.table_edit_data.sub_opinion,
          status_: this.table_edit_data.status_
        }
        Api.http_paistable5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.table_edit_dialog.open = false
            this.table_get_all_data(
              (this.tb.page - 1) * this.tb.pageSize,
              this.tb.pageSize
            )
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  table_init() {
    this.table_get_all_data(
      (this.tb.page - 1) * this.tb.pageSize,
      this.tb.pageSize
    )
  }
  created() {
    this.table_init()
  }
}
